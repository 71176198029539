export interface ABTestConfig {
  test_name: string
  bPatternRatio: number // Percentage of B pattern, 100 - bPatternRatio is percentage of A pattern
  keepSession: boolean // If true, keep pattern value in cookie, otherwise keep pattern value in store state
  startTime?: string
  endTime?: string
}

export const TOP_FV_AB_TEST_CONFIG: ABTestConfig = {
  test_name: 'TOP_FV_AB_TEST',
  bPatternRatio: 30, // 30% B pattern, 70% A pattern
  keepSession: true,
  startTime: '2025-03-25 11:00:00',
  endTime: '2025-03-31 12:50:00'
}

export const SIMILAR_EVENT_GROUP_AB_TEST_CONFIG: ABTestConfig = {
  test_name: 'SIMILAR_EVENT_GROUP_AB_TEST',
  bPatternRatio: 15,
  keepSession: true,
  startTime: '2025-03-24 00:00:00',
  endTime: '2025-04-09 00:00:00'
}

// Add more ABTestConfig here if needed

export const ABTestConfigList: ABTestConfig[] = [
  TOP_FV_AB_TEST_CONFIG,
  SIMILAR_EVENT_GROUP_AB_TEST_CONFIG
  // Add more ABTestConfig here if needed
]
